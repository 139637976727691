.trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

.trigger:hover {
  color: #1890ff;
}

.logo {
  height: 50px;
  /* margin: 1px; */
  /* background: rgba(255, 255, 255); */
  background: #f8ddaa;
  /* background: rgb(249, 228, 204); */
  background-image: image();
}

.site-layout {
  background: #fff;
  /* background: rgb(212, 210, 90);
  background: radial-gradient(
    circle,
    rgba(212, 210, 90, 0.006039915966386533) 0%,
    rgba(166, 173, 170, 1) 100%
  ); */
}

.site-layout-background {
  background: #f8ddaa;
  /* background: rgb(249, 228, 204); */
  /* height: 50px; */
}
